'use client'
import type { FC, ReactNode } from 'react'
import { Box, Spacer } from '@chakra-ui/react'
import NavBar from '@/app/(public)/components/NavBar'
import { NAVBAR_HEIGHT } from '@/constants'

interface NavLayoutProps {
  children: ReactNode | ReactNode[]
}

const NavLayout: FC<NavLayoutProps> = ({ children }) => {
  return (
    <>
      <NavBar borderBottomWidth={1} borderBottomColor="border" h={NAVBAR_HEIGHT} />
      <Spacer h={NAVBAR_HEIGHT} />
      <Box as="main">{children}</Box>
    </>
  )
}

export default NavLayout
